import React from "react";
import Notes from "./Notes";

const Home = () => {


  return (
    <>
      <Notes/>
    </>
  );
};

export default Home;
